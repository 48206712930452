export default {
  alternativeController: {
    id: 'alternative-controller',
    defaultMessage: 'Controller',
  },
  alternativeCurrent: {
    id: 'alternative-current',
    defaultMessage: 'Current Slide',
  },
  alternativeNext: {
    id: 'alternative-next',
    defaultMessage: 'Next',
  },
  alternativePrevious: {
    id: 'alternative-previous',
    defaultMessage: 'Previous',
  },
}
