
import { mapGetters } from 'vuex'

export default {
  props: {
    generatorsNumber: {
      type: Number,
      default: 10,
    },
    slotsPerGenerator: {
      type: Number,
      default: 5,
    },
    zIndex: {
      type: Number,
      default: 1,
    },
    height: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      isEnabled: 'precipitation/isEnabled',
    }),
    generators() {
      const generators = Array(this.generatorsNumber)
      generators.fill('snow')

      return generators
    },
  },

  methods: {
    animationStyles() {
      const MILLISECONDS_PER_PERCENTAGE = 10
      const fallFactor =
        (MILLISECONDS_PER_PERCENTAGE + Math.log(this.height)) * this.height
      const shakeMaximumDelay = 4000

      // Random animation delays to prevent precipitation to follow the same path
      const fallAnimationDelay = Math.floor(Math.random() * fallFactor)
      const shakeAnimationDelay = Math.floor(Math.random() * shakeMaximumDelay)

      const shakeAnimationMinimumDuration = 4000
      const shakeAnimationMaximumDuration = 10000
      const shakeAnimationRangeDuration =
        shakeAnimationMaximumDuration - shakeAnimationMinimumDuration

      // Random animation durations to create a parallax effect
      const fallAnimationDuration = Math.floor(
        Math.random() * fallFactor + fallFactor,
      )
      const shakeAnimationDuration = Math.floor(
        Math.random() * shakeAnimationRangeDuration +
          shakeAnimationMinimumDuration,
      )

      return {
        ...this.size(),
        zIndex: this.zIndex,
        animationDelay: `${fallAnimationDelay}ms, -${shakeAnimationDelay}ms`,
        animationDuration: `${fallAnimationDuration}ms, ${shakeAnimationDuration}ms`,
      }
    },

    size() {
      const minimumFontSize = 6
      const maximumFontSize = 32
      const fontSizeRange = maximumFontSize - minimumFontSize

      // Random values on size so the precipitation is more organic
      const slotSize = Math.floor(
        Math.random() * fontSizeRange + minimumFontSize,
      )

      return {
        fontSize: `${slotSize}px`,
        width: `${slotSize}px`,
      }
    },
  },
}
