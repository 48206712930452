// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes LYVCGPBtwAJAFtdMbcW8Uw\\=\\={0%{top:-3.2rem}to{top:100%}}@keyframes -u3j\\+4YYLDzPtHLPrLTeig\\=\\={0%{transform:translateX(-3rem) rotate(0deg)}50%{transform:translateX(3rem) rotate(50deg)}to{transform:translateX(-3rem) rotate(0deg)}}@media (prefers-reduced-motion:no-preference){.lchbJkT2RG70cO2dQzEWrg\\=\\={animation-iteration-count:infinite,infinite;animation-name:LYVCGPBtwAJAFtdMbcW8Uw\\=\\=,-u3j\\+4YYLDzPtHLPrLTeig\\=\\=;animation-timing-function:linear,ease-in-out}.Y3Mcha5zmnQJXFeblELLtQ\\=\\={animation-direction:normal,reverse;animation-name:LYVCGPBtwAJAFtdMbcW8Uw\\=\\=,-u3j\\+4YYLDzPtHLPrLTeig\\=\\=}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fall": "lchbJkT2RG70cO2dQzEWrg==",
	"snow-fall": "LYVCGPBtwAJAFtdMbcW8Uw==",
	"snow-shake": "-u3j+4YYLDzPtHLPrLTeig==",
	"fallInverted": "Y3Mcha5zmnQJXFeblELLtQ=="
};
module.exports = ___CSS_LOADER_EXPORT___;
