
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevCarousel } from '@ds/components/Carousel'
import { RevIllustration } from '@ds/components/Illustration'
import isEmpty from 'lodash/isEmpty'

import Precipitation from '@core/components/Precipitation/Precipitation'
import { trackCarouselClick } from '@tracking/events'

import ImageRender from '../private/ImageRender'
import { mobileMediumThreshold } from '../private/ImageRender/ImageRender.constant'

import CAROUSEL from './Carousel.constant'
import translations from './Carousel.translations'

export default {
  components: {
    RevCarousel,
    ImageRender,
    Precipitation,
    RevIllustration,
  },
  props: {
    slides: {
      type: Array,
      required: true,
    },
    autoplay: {
      type: Boolean,
      default: CAROUSEL.AUTOPLAY,
    },
    controllerOverlay: {
      type: Boolean,
      default: CAROUSEL.CONTROLLER_OVERLAY,
    },
    controllerPosition: {
      type: String,
      default: CAROUSEL.CONTROLLER_POSITION,
    },
    controllerVariant: {
      type: String,
      default: CAROUSEL.CONTROLLER_VARIANT,
    },
    displayNavigation: {
      type: Boolean,
      default: CAROUSEL.DISPLAY_NAVIGATION,
    },
    interval: {
      type: Number,
      default: CAROUSEL.INTERVAL,
    },
    tracking: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    translations: () => translations,
    carouselSlides() {
      const handleSlideClickDefault = () => {
        // empty function
      }
      const { name } = this.$route

      return this.slides.map((slide, index) => {
        return {
          ...slide,
          tag: this.getTag(slide),
          key: slide.image?.src,
          target: this.getTarget(slide),
          isLoading: this.loading(index),
          handleSlideClick: !isEmpty(slide.link)
            ? () => this.handleSlideClick({ slide, name, index })
            : handleSlideClickDefault,
        }
      })
    },
    trackingData() {
      if (Array.isArray(this.tracking) && !isEmpty(this.tracking)) {
        return this.tracking
      }

      return {
        name: this.$route.name,
        creatives: this.slides.map((slide) => ({ creative: slide.image?.alt })),
      }
    },
  },
  created() {
    this.mobileMediumThreshold = mobileMediumThreshold
  },
  methods: {
    // [ACQ-72] TODO: Use DS tracking bus
    handleSlideClick({ slide, name, index }) {
      trackCarouselClick({
        creative: slide.image?.alt,
        name,
        position: `${name}_${index}`,
      })
    },
    handleChange({ index }) {
      this.$emit('track-impression', {
        alternativeText: this.slides[index].image?.alt,
        position: index,
      })
    },
    getTag({ link }) {
      return isEmpty(link) ? 'div' : RevButtonBase
    },
    loading(index) {
      return index === 0 ? 'eager' : 'lazy'
    },
    getTarget({ link }) {
      // returning `false` will prevent the target attribute to be rendered at all (https://vuejs.org/v2/guide/syntax.html#Attributes)
      return link?.targetBlank ? '_blank' : false
    },
  },
}
